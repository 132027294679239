import styled from 'styled-components';
import theme from '../../../../../theme';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding: 0px 30px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 100%;
`;

export const StyledCardContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 32px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 100%;
    @media (max-width: 1000px) {
        display: flex;
        flex-direction: column;
    }
`;

export const StyledFormWithButton = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
`;

export const StyledFormContainer = styled.div`
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    margin-top: 20px;
    border: 1px solid ${theme.colors.defaultBorder};
    border-radius: 16px 16px 0 0;
    padding: 48px 80px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    position: relative;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const StyledCompanyContainer = styled.div`
    width: 100%;
    max-width: 375px;
    @media (max-width: 1000px) {
        min-width: 100%;
        max-width: 200px;
    }
`;

export const StyledFormTitleContainer = styled.div`
    display: flex;
    width: 100%;
    margin: 20px 0px 0px 0px;
    display: flex;
    justify-content: center;
    @media (max-width: 1300px) {
        width: 100%;
        margin: 20px 0px 0px 70px;
    }
`;
export const StyledPolicyStatusContainer = styled.div`
    position: absolute;
    z-index: 1;
    top: 25px;
    right: 80px;
    padding: 15px;
`;


export const StyledFormTitle = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.landing};
    font-weight: bold;
    text-transform: uppercase;
    padding: 20px 20px 10px 0px;
  `
);

export const StyledConfimBtnContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const StyledConfimBtnWrapper = styled.div`
    margin: 10px;
    width: 350px;
`;

export const StyledIconContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
`;

export const StyledIcon = styled.img`
    overflow: hidden;
    min-height: 10px;
    min-width: 10px;
    cursor: pointer;
`;

export const StyledIconText = styled.div`
    color: ${(p) => p.theme.colors.muted};
    margin-left: 10px;
    cursor: pointer;
`;

export const StyledErrorText = styled.span`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${(p) => p.theme.colors.error};
    margin: 20px 0px;
    margin-bottom: 0;
    font-size: ${(p) => p.theme.fontSizes.label};
    text-align: center;
`;


export const StyledNicknameContainer = styled.div`
    gap: 16px;
    display: flex;
    flex-direction: column;
`;

export const StyledNicknameHelperContainer = styled.div`
    background: ${theme.colors.surface};
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border-radius: 16px;
    margin-top: 20px;
`;

export const StyledNicknameHelperContainerHeader = styled.header`
    display: flex;
    gap: 4px;
    align-items: center;
`;

export const StyledNicknameHelperDescriptionContainer = styled.div`
    border-top: 1px solid ${theme.colors.defaultBorder};
    padding-top: 16px;
    display: flex;
    gap: 24px;
`;

export const StyledNicknameHelperPolicyDescriptionItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex: 1;
`;

export const StyledNicknameHelperPolicyDescriptionItemHeader = styled.header`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const StyledNicknameHelperPolicyDescriptionItemData = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const StyledNicknameHelperPolicyDescriptionItemDivider = styled.div`
    width: 1px;
    min-height: 100%;
    background-color: ${theme.colors.defaultBorder};
`;

export const StyledPolicySubmitButtonContainer = styled.div`
    border: 1px solid ${theme.colors.defaultBorder};
    border-top: none;
    padding: 24px;
`;

export const StyledPolicySubmitButton = styled.div`
    width: 350px;
    margin: auto;
`;
