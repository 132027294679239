import React, { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StyledCompanyCard, StyledContainer, StyledPolicyFormContainer } from './styles';
import PolicyForm from '../../../../containers/AddPolicy/PolicyForm';
import { CompanyCard } from '../../../../containers';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../redux';
import { IPolicy } from '../../../../../redux/slices/PolicySlice/types';
import { fetchActivePoliciesList } from '../../../../../adapters';
import { useToast } from '../../../../hooks/useToast';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../../../utils/constants/Routes';
import { POLICY_ADD_ERROR_MESSAGES } from './constants';

const AddPolicy: FC = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const location = useLocation();
    const { companyId } = location.state || {};
    const [activePolicyList, setActivePolicyList] = React.useState<{ policies: IPolicy[] } & { loading: boolean }>({
        policies: [],
        loading: true
    });
    const selectedCompany = useSelector(
        (state: ReduxState) => state.companySlice.companyList.data?.filter((company) => company.id === companyId)[0]
    );

    const fetchActivePolicies = async () => {
        try {
            !activePolicyList.loading && setActivePolicyList({ policies: activePolicyList.policies, loading: true });
            const activePolicyListResponse = (await fetchActivePoliciesList(companyId)).data;
            setActivePolicyList({ policies: [...activePolicyListResponse], loading: false });
        } catch (e) {
            toast?.displayToast('error', '', POLICY_ADD_ERROR_MESSAGES.ERROR_OCCURRED);
        }
    };

    useEffect(() => {
        if (!companyId) {
            toast?.displayToast('error', '', POLICY_ADD_ERROR_MESSAGES.NO_COMPANY_INFO);
            navigate(`${EXTERNAL_ROUTES.APP}/${INTERNAL_ROUTES.COMPANY_DIRECTORY.route}`);
        } else fetchActivePolicies();
    }, []);

    return companyId ? (
        <StyledContainer>
            <StyledPolicyFormContainer>
                <PolicyForm companyId={companyId} activePolicyList={activePolicyList.policies} />
                <StyledCompanyCard>
                    {selectedCompany && <CompanyCard {...selectedCompany} isPlacementForm={false} />}
                </StyledCompanyCard>
            </StyledPolicyFormContainer>
        </StyledContainer>
    ): null;
};

export default AddPolicy;
