import { IDropdownItem } from '@loophealth/loop-ui-web-library/dist/types/atoms/Form/DropDown/types';
import {
    IPolicy,
    IPolicySubtype,
    IPolicyType
} from '../../../../redux/slices/PolicySlice/types';
import { IRadioButtonOptions } from '../../../../utils/constants/PlacementSlips/RadioButtonOptions';
import { IPolicySubtypeEnum, POLICY_SUBTYPE_OPTIONS } from './constants';
import { IPolicySubtypeRadioOption } from './types';

export const getBasePoliciesDropdownOptions = (
    selectedType: IPolicyType | '',
    activePolicies?: IPolicy[]
): IDropdownItem[] => {
    if (!selectedType || !activePolicies) return [];
    return activePolicies
        .filter(
            (p) =>
                p.policyType === selectedType && p.policyPlacementStatus !== 'Draft'
        )
        .map((p) => ({
            value: p.id,
            name: `${p.insurerName} (${p.policyType || 'N/A'}) (${p.id || 'N/A'})`
        }));
};

export const getPolicySubTypeOptions = (
    policyType: IPolicyType | ''
): IPolicySubtypeRadioOption[] => {
    if (policyType) {
        return POLICY_SUBTYPE_OPTIONS.filter((option) =>
            option.policyTypes.includes(policyType) && !option.disabled
        );
    }
    return [];
};

const getSubtext = (
    subTypeOption: IRadioButtonOptions | null,
    hasBenefits: boolean
) => {
    let subText = '';
    const subType = subTypeOption?.id as IPolicySubtype;
    switch (subType) {
    case IPolicySubtypeEnum.MODULAR:
        subText = hasBenefits ? ' Modular plus Benefits' : ' Modular';
        break;
    case IPolicySubtypeEnum.OPD:
    case IPolicySubtypeEnum.TOPUP:
    case IPolicySubtypeEnum.PARENTAL:
        subText = ` ${subTypeOption?.value}`;
        break;
    }
    return subText;
};

export const getTemplateDownloadTitle = (
    type: IPolicyType | '',
    subTypeOption: IRadioButtonOptions | null,
    hasBenefits: boolean
): string => {
    const subText = getSubtext(subTypeOption, hasBenefits);
    return `${type}${subText} Placement Slip`;
};
