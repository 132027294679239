import {
    Dialog,
    Form,
    LoopButton,
    Modal,
    Shadow,
    Toast,
    Tooltip,
    Typography,
    UploadPillSelection
} from '@loophealth/loop-ui-web-library';
import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ClipboardIcon } from '../../../../assets/icons/ClipboardIcon';
import { ExcelIcon, InfoIcon, TrashIconCircle1 } from '../../../../assets/img';
import { setPlacementFormDetails } from '../../../../redux/slices/PolicySlice';
import {
    IPolicy,
    IPolicyType
} from '../../../../redux/slices/PolicySlice/types';
import theme from '../../../../theme';
import WithThemeProvider from '../../../../theme/WithThemeProvider';
import {
    excelToJson,
    fileDownload,
    placementDataFormatter
} from '../../../../utils/common';
import {
    IRadioButtonOptions,
    radioButtonOptions
} from '../../../../utils/constants/PlacementSlips/RadioButtonOptions';
import { POLICY_TYPE } from '../../../../utils/constants/PolicyTypeConstants';
import {
    EXTERNAL_ROUTES,
    INTERNAL_ROUTES
} from '../../../../utils/constants/Routes';
import UploadModalFile from '../../../atoms/UploadModal/UploadModalFile';
import { fileSizeToString } from '../../ValidationSummaryDetails/utils';
import { IPolicySubtypeEnum } from './constants';
import {
    StyledAdditionalBenefitsContainer,
    StyledAdditionalBenefitsText,
    StyledAddPolicyButton,
    StyledAddPolicySubmitButtonContainer,
    StyledCheckboxContainer,
    StyledCheckboxInput,
    StyledHorizontalDivider,
    StyledInfoIcon,
    StyledPlansNumberHelperText,
    StyledPolicyForm,
    StyledPolicyFormHeader,
    StyledPolicyFormHeaderTitle,
    StyledPolicyFormWrapper,
    StyledPolicyInputs,
    StyledRadioButtonContainer,
    StyledSelectedFile,
    StyledTemplateButton,
    StyledTemplateContainer,
    StyledTemplateInfo,
    StyledTemplateInfoTitleContainer,
    StyledUploadPill,
    StyledUploadPillContainer,
    StyledUploadPillHeader,
    StyledUploadPillHelper,
    StyledUploadPillWrapper
} from './styles';
import { IPolicyForm } from './types';
import {
    getBasePoliciesDropdownOptions,
    getPolicySubTypeOptions,
    getTemplateDownloadTitle
} from './utils';

const PolicyForm: FC<IPolicyForm> = ({ companyId, activePolicyList }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = Toast.useToast();
    const placementSlip = React.useRef<Record<string, unknown>[]>([]);
    const [policyType, setPolicyType] = React.useState<IPolicyType | ''>('');
    const [policySubtype, setPolicySubtype] =
    React.useState<IRadioButtonOptions | null>(null);
    const [hasAdditionalBenefits, setHasAdditionalBenefits] =
    React.useState<IRadioButtonOptions | null>(null);
    const [basePolicyPlanId, setBasePolicyPlanId] = React.useState<string>('');
    const [numberOfPlans, setNumberofPlans] = React.useState<string>('');
    const [selectedFiles, setSelectedFiles] = React.useState<
    Record<string, unknown>[]
  >([]);
    const [isCheckboxSelected, setIsCheckboxSelected] =
    React.useState<boolean>(false);
    const [fileError, setFileError] = useState<string>('');
    const [showDeleteFilePrompt, setShowDeleteFilePrompt] = useState(false);
    const [plansError, setPlansError] = React.useState('');
    const [loading, setLoading] = React.useState<boolean>(false);

    const isTopUpPolicy = policySubtype?.id === IPolicySubtypeEnum.TOPUP;
    const isParentalPolicy = policySubtype?.id === IPolicySubtypeEnum.PARENTAL;
    const isOPDPolicy = policySubtype?.id === IPolicySubtypeEnum.OPD;
    const isModularPolicy = policySubtype?.id === IPolicySubtypeEnum.MODULAR;

    const toShowAdditionalBenefits = policyType === 'GMC' && isModularPolicy;

    const showUploadModal =
    policyType &&
    policySubtype &&
    (!toShowAdditionalBenefits || hasAdditionalBenefits) &&
    (!isTopUpPolicy || basePolicyPlanId);

    const isFileSelected = selectedFiles.length > 0;

    const basePolicyPlanOptions = useMemo(
        () => getBasePoliciesDropdownOptions(policyType, activePolicyList),
        [activePolicyList, policyType]
    );

    const subTypeRadioOptions = useMemo(
        () => getPolicySubTypeOptions(policyType),
        [policyType]
    );

    const templateDownloadTitle = useMemo(
        () =>
            getTemplateDownloadTitle(
                policyType,
                policySubtype,
                hasAdditionalBenefits?.id === 'true'
            ),
        [policySubtype, policyType, hasAdditionalBenefits]
    );

    const navigateToPlacementForm = (id: string) => {
        navigate(
            `${EXTERNAL_ROUTES.APP}/${INTERNAL_ROUTES.PLACEMENT_FORM.route}?id=${id}`
        );
    };

    const handleLegacyFlow = () => {
        excelToJson(
      selectedFiles[0] as unknown as Blob,
      async (excelJSON) => {
          try {
              setFileError('');

              setLoading(true);

              placementSlip.current = excelJSON;
              if (placementSlip.current && policyType) {
                  const placementDetails: IPolicy = placementDataFormatter(
                      placementSlip.current,
                      policyType
                  ) as unknown as IPolicy;

                  if (placementDetails.error) {
                      setFileError(placementDetails.error);
                      setLoading(false);
                  } else {
                      if (placementDetails.policyType.toLowerCase() !== 'gmc') {
                          placementDetails['sumInsuredApproach'] = 'Individual';
                      }

                      if (!placementDetails.policyStartDate) {
                          placementDetails.policyStartDate = new Date();
                          placementDetails.policyStartDate.setHours(0, 0, 0, 0);
                      }
                      if (!placementDetails.policyEndDate) {
                          placementDetails.policyEndDate = new Date();
                          placementDetails.policyEndDate.setHours(0, 0, 0, 0);
                      }
                      const isCommonPool =
                placementDetails?.sumInsuredApproach.toLowerCase() ===
                'individual'
                    ? false
                    : true;

                      // Adding the mandatory fields not being recorded from the placement slip
                      const updatedDetails = {
                          ...placementDetails,
                          isTopUpPolicy,
                          isParentalPolicy,
                          isOPDPolicy,
                          basePolicyPlanId: basePolicyPlanId,
                          companyId: companyId,
                          policyPlacementStatus: 'Draft',
                          isCommonPool: isCommonPool,
                          installmentPremium: placementDetails.installmentPremium || null,
                          finalAgreedRate: placementDetails.finalAgreedRate || null
                      };
                      dispatch(
                          setPlacementFormDetails({
                              placementFormDetails: updatedDetails
                          })
                      );
                      setLoading(false);
                      navigateToPlacementForm(companyId);
                  }
              }
          } catch (e) {
              setLoading(false);
              throw e;
          }
      },
      'Policy Data'
        );
    };

    const handleModularFlow = () => {
        setLoading(true);
        try {
            /*
                todo:
                do api call
                if success show confirmation modal
                else if errorFile in response, set errorFile, message, and show error toast
            */
        } catch (e) {
            // todo: handle tempered file error
            const message = (e as Error).message || 'Something went wrong';
            toast?.error(message);
        }
        setLoading(false);
    };

    const handleContinueClick = () => {
        if (policyType === 'GMC' && isModularPolicy) {
            handleModularFlow();
        } else handleLegacyFlow();
    };

    const handlePolicyChange = (pt: string) => {
        if (policyType === pt) return;
        setPolicyType(pt as IPolicyType);
        setPolicySubtype(null);
        setBasePolicyPlanId('');
        setNumberofPlans('');
        setHasAdditionalBenefits(null);
        setSelectedFiles([]);
    };

    const handlePolicySubtypeChange = (pst: IRadioButtonOptions) => {
        if (policySubtype === pst) return;
        setPolicySubtype(pst);
        setBasePolicyPlanId('');
        setNumberofPlans('');
        setHasAdditionalBenefits(null);
        setSelectedFiles([]);
    };

    const handleNumberOfPlansChange = (e: ChangeEvent<HTMLInputElement>) => {
        const v = e.target.value;
        setNumberofPlans(v);
        const n = Number(v);
        if (!v) {
            setPlansError('Please enter number of plans');
        } else if (isNaN(n) || n < 1 || n > 6) {
            setPlansError('Please enter a number from 1 to 6');
        } else setPlansError('');
    };

    const handleOnCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIsCheckboxSelected(e.target.checked);
    };

    const handleTemplateDownload = () => {
    // todo: do api call
        const pType =
      policyType?.toLowerCase() === 'covid'
          ? policyType.charAt(0).toUpperCase() + policyType.slice(1).toLowerCase()
          : policyType;
        const fileName = `Template/(Template)-${pType}%20Placement%20Slip.xlsx`;
        fileDownload(fileName);
    };

    const handleFileDelete = () => {
        setShowDeleteFilePrompt(true);
    };

    const handleDeleteFileConfirmation = () => {
        setSelectedFiles([]);
        setShowDeleteFilePrompt(false);
    };

    const getButtonVariant = () => {
        return policyType &&
      policySubtype &&
      (!toShowAdditionalBenefits || hasAdditionalBenefits) &&
      (!isModularPolicy || (numberOfPlans && isCheckboxSelected)) &&
      (!isTopUpPolicy || basePolicyPlanId) &&
      selectedFiles.length
            ? 'filled'
            : 'disabled';
    };

    return (
        <StyledPolicyFormWrapper>
            <Shadow variant="bottom">
                <WithThemeProvider>
                    <StyledPolicyForm>
                        <StyledPolicyInputs>
                            <StyledPolicyFormHeader>
                                <StyledPolicyFormHeaderTitle>
                                    <ClipboardIcon />
                                    <Typography variant="title2" weight="semiBold">
                    Add a new policy
                                    </Typography>
                                </StyledPolicyFormHeaderTitle>
                                <StyledHorizontalDivider />
                            </StyledPolicyFormHeader>
                            <Form.FormField
                                id="policy-type-dropdown"
                                label="Choose Policy Type"
                            >
                                <Form.Dropdown
                                    listItems={POLICY_TYPE.map((policyType) => ({
                                        name: policyType,
                                        value: policyType
                                    }))}
                                    onClick={handlePolicyChange}
                                    placeholder="Select"
                                    selectedItem={policyType}
                                />
                            </Form.FormField>

                            {policyType && (
                                <Form.FormField
                                    id="policy-subtype-radio"
                                    label={`Choose the type of ${policyType} Policy`}
                                    required={true}
                                >
                                    <StyledRadioButtonContainer>
                                        <Form.RadioButton
                                            radioItems={subTypeRadioOptions}
                                            selectedItem={policySubtype}
                                            onClick={handlePolicySubtypeChange}
                                        />
                                    </StyledRadioButtonContainer>
                                </Form.FormField>
                            )}

                            {isTopUpPolicy && (
                                <Form.FormField
                                    id="top-up-dropdown"
                                    label="Please select a base policy for Top-Up"
                                    required={true}
                                >
                                    <Form.Dropdown
                                        listItems={basePolicyPlanOptions}
                                        placeholder="Select Base Policy"
                                        selectedItem={basePolicyPlanId}
                                        onClick={setBasePolicyPlanId}
                                        emptyState="No base policies available"
                                    />
                                </Form.FormField>
                            )}

                            {isModularPolicy && (
                                <Form.FormField
                                    id="modular-plans-input"
                                    label="Enter the number of plans in the modular policy"
                                    required
                                >
                                    <Form.Input
                                        placeholder="Enter number"
                                        value={numberOfPlans}
                                        onChange={handleNumberOfPlansChange}
                                        type="number"
                                        error={plansError}
                                    />
                                    {!plansError && (
                                        <StyledPlansNumberHelperText>
                                            <Typography variant="small" color="secondary">
                        This should be a total of base plus other plans
                                            </Typography>
                                        </StyledPlansNumberHelperText>
                                    )}
                                </Form.FormField>
                            )}

                            {toShowAdditionalBenefits && (
                                <StyledAdditionalBenefitsContainer>
                                    <StyledAdditionalBenefitsText>
                                        <Typography variant="small" weight="medium">
                      Does this policy have additional benefits?
                                        </Typography>
                    &nbsp;
                                        <Tooltip
                                            type="informative"
                                            beak="top-center"
                                            // eslint-disable-next-line max-len
                                            text="These are insurance and non-insurance benefits such as Critical Illness Plan, Family Care Plan, Gym Membership etc"
                                        >
                                            <StyledInfoIcon src={InfoIcon} />
                                        </Tooltip>
                                    </StyledAdditionalBenefitsText>
                                    <StyledRadioButtonContainer>
                                        <Form.RadioButton
                                            radioItems={radioButtonOptions}
                                            selectedItem={hasAdditionalBenefits}
                                            onClick={setHasAdditionalBenefits}
                                        />
                                    </StyledRadioButtonContainer>
                                </StyledAdditionalBenefitsContainer>
                            )}

                            {showUploadModal && (
                                <>
                                    <StyledHorizontalDivider />
                                    <StyledUploadPillWrapper>
                                        <StyledUploadPillHeader>
                                            <Typography variant="medium" weight="medium">
                        Upload placement slip
                                            </Typography>
                                            <Typography variant="extraSmall" color="secondary">
                        Download the template, fill in the details and upload
                        the completed placement slip to create this policy
                                            </Typography>
                                        </StyledUploadPillHeader>
                                        {!isFileSelected && (
                                            <StyledTemplateContainer>
                                                <StyledTemplateInfo>
                                                    <StyledTemplateInfoTitleContainer>
                                                        <img src={ExcelIcon} />
                                                        <Typography variant="extraSmall" weight="semiBold">
                                                            {templateDownloadTitle}
                                                        </Typography>
                                                    </StyledTemplateInfoTitleContainer>
                                                    <Typography variant="extraSmall">
                            Use this template with predefined columns and enter
                            policy coverage details
                                                    </Typography>
                                                </StyledTemplateInfo>
                                                <StyledTemplateButton>
                                                    <LoopButton
                                                        variant="outline"
                                                        size="small"
                                                        onClick={handleTemplateDownload}
                                                        backgroundColor={theme.colors.white}
                                                    >
                            Download
                                                    </LoopButton>
                                                </StyledTemplateButton>
                                            </StyledTemplateContainer>
                                        )}
                                        <StyledUploadPillContainer>
                                            {selectedFiles.length > 0 ? (
                                                <StyledSelectedFile>
                                                    <UploadModalFile
                                                        onDelete={handleFileDelete}
                                                        fileName={
                                                            selectedFiles[0] instanceof File
                                                                ? selectedFiles[0].name
                                                                : 'Placement slip.xlsx'
                                                        }
                                                        fileSize={fileSizeToString(
                              selectedFiles[0].size as number
                                                        )}
                                                    />
                                                </StyledSelectedFile>
                                            ) : (
                                                <StyledUploadPill>
                                                    <UploadPillSelection
                                                        formats={['.xls', '.xlsx']}
                                                        onUpload={setSelectedFiles}
                                                        selected={selectedFiles}
                                                        onError={(e) => setFileError(e.message)}
                                                        maxFiles={1}
                                                        maxSize={25}
                                                    />
                                                </StyledUploadPill>
                                            )}
                                            {fileError && (
                                                <Typography variant="extraSmall" color="error">
                                                    {fileError}
                                                </Typography>
                                            )}
                                            {!isFileSelected && (
                                                <StyledUploadPillHelper>
                                                    <Typography variant="extraSmall">
                            Supported Formats: XLS, XLSX
                                                    </Typography>
                                                    <Typography variant="extraSmall">
                            Maximum Size: 25MB
                                                    </Typography>
                                                </StyledUploadPillHelper>
                                            )}
                                        </StyledUploadPillContainer>
                                    </StyledUploadPillWrapper>
                                    {isModularPolicy && (
                                        <StyledCheckboxContainer>
                                            <StyledCheckboxInput
                                                type="checkbox"
                                                checked={isCheckboxSelected}
                                                onChange={handleOnCheckboxChange}
                                            />
                                            <Typography variant="small">
                        I confirm that the placement slip has been approved by
                        the Operations, Claims and CSM heads
                                            </Typography>
                                        </StyledCheckboxContainer>
                                    )}
                                </>
                            )}
                        </StyledPolicyInputs>

                        {showUploadModal && (
                            <StyledAddPolicySubmitButtonContainer>
                                <StyledAddPolicyButton>
                                    <LoopButton
                                        isLoading={loading}
                                        variant={getButtonVariant()}
                                        onClick={handleContinueClick}
                                    >
                    Continue
                                    </LoopButton>
                                </StyledAddPolicyButton>
                            </StyledAddPolicySubmitButtonContainer>
                        )}
                    </StyledPolicyForm>
                </WithThemeProvider>
            </Shadow>
            <Modal
                isVisible={showDeleteFilePrompt}
                setIsVisible={setShowDeleteFilePrompt}
            >
                <Dialog
                    variant="vertical"
                    icon={TrashIconCircle1}
                    title="Are you sure you want to delete the placement slip?"
                    description="You’ll have to re-upload the file if you delete it."
                    primaryButtonText="Delete File"
                    buttonVariant="error"
                    onPrimaryButtonClicked={handleDeleteFileConfirmation}
                    onSecondaryButtonClicked={() => setShowDeleteFilePrompt(false)}
                    secondaryButtonText="Cancel"
                />
            </Modal>
        </StyledPolicyFormWrapper>
    );
};

export default PolicyForm;
