import { isModularPoliciesEnabled } from '../../../../utils/featureFlags';
import { IPolicySubtypeRadioOption } from './types';

export const POLICY_SUBTYPE_OPTIONS: IPolicySubtypeRadioOption[] = [
    {
        value: 'Modular',
        id: 'MODULAR',
        policyTypes: ['GMC'],
        disabled: !isModularPoliciesEnabled
    },
    {
        value: 'IPD',
        id: 'IPD',
        policyTypes: ['GMC', 'GPA', 'GTL', 'COVID']
    },
    {
        value: 'Top-up',
        id: 'TOPUP',
        policyTypes: ['GMC', 'GPA', 'GTL', 'COVID']
    },
    {
        value: 'Parental',
        id: 'PARENTAL',
        policyTypes: ['GMC']
    },
    {
        value: 'OPD',
        id: 'OPD',
        policyTypes: ['GMC']
    }
];

export enum IPolicySubtypeEnum {
    MODULAR = 'MODULAR',
    IPD = 'IPD',
    TOPUP = 'TOPUP',
    PARENTAL = 'PARENTAL',
    OPD = 'OPD'
}
