import React from "react";
import { IGenericIconProps } from "./types";
import theme from "../../theme";

const ClipboardIcon: React.FC<IGenericIconProps> = ({
  width = 24,
  height = 24,
  color = theme.colors.consultViolet,
  strokeWidth = 2,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth={strokeWidth}
    >
      <path
        d="M16.7411 2.33325H11.2577C10.0444 2.33325 9.05273 3.31325 9.05273 4.52659V5.62325C9.05273 6.83659 10.0327 7.81659 11.2461 7.81659H16.7411C17.9544 7.81659 18.9344 6.83659 18.9344 5.62325V4.52659C18.9461 3.31325 17.9544 2.33325 16.7411 2.33325Z"
        fill={color}
      />
      <path
        d="M20.1129 5.62331C20.1129 7.47831 18.5962 8.99498 16.7412 8.99498H11.2579C9.40289 8.99498 7.88622 7.47831 7.88622 5.62331C7.88622 4.96998 7.18622 4.56164 6.60289 4.86498C4.95789 5.73998 3.83789 7.47831 3.83789 9.47331V20.4516C3.83789 23.3216 6.18289 25.6666 9.05289 25.6666H18.9462C21.8162 25.6666 24.1612 23.3216 24.1612 20.4516V9.47331C24.1612 7.47831 23.0412 5.73998 21.3962 4.86498C20.8129 4.56164 20.1129 4.96998 20.1129 5.62331ZM14.4429 19.775H9.33289C8.85456 19.775 8.45789 19.3783 8.45789 18.9C8.45789 18.4216 8.85456 18.025 9.33289 18.025H14.4429C14.9212 18.025 15.3179 18.4216 15.3179 18.9C15.3179 19.3783 14.9212 19.775 14.4429 19.775ZM17.4996 15.1083H9.33289C8.85456 15.1083 8.45789 14.7116 8.45789 14.2333C8.45789 13.755 8.85456 13.3583 9.33289 13.3583H17.4996C17.9779 13.3583 18.3746 13.755 18.3746 14.2333C18.3746 14.7116 17.9779 15.1083 17.4996 15.1083Z"
        fill={color}
      />
    </svg>
  );
};

export { ClipboardIcon };
