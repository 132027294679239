import styled from 'styled-components';
import theme from '../../../../../theme';

export const StyledContainer = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 44px 50px 64px 50px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
`;

export const StyledPolicyFormContainer = styled.section`
    width: 100%;
    gap: 50px;
    display: flex;
    flex-direction: row;
    padding-bottom: 60px;
    @media (max-width: 1000px) {
        flex-direction: column;
    }
`;

export const StyledNotice = styled.section`
    width: 100%;
    padding: 16px 32px;
    margin-top: 20px;
    border-radius: 10px;
    background-color: ${theme.colors.planeGreenLight};
    display: flex;
    align-items: flex-start;
    gap: 12px;
`;

export const StyledAlertData = styled.div`
    gap: 8px;
    display: flex;
    flex-direction: column;
`;

export const StyledCompanyCard = styled.div`
    max-width: 375px;
    @media (max-width: 1000px) {
        max-width: 100%;
    }
`;
