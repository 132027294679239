import styled from 'styled-components';
import theme from '../../../../theme';

export const StyledPolicyFormWrapper = styled.section`
    flex: 1;
    margin-top: 20px;
    border-radius: 12px;
`;

export const StyledPolicyForm = styled.section`
    background-color: ${(p) => p.theme.colors.white};
    border-radius: 12px;
    display: flex;
    flex-direction: column;
`;

export const StyledPolicyFormHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const StyledPolicyFormHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px;
`;

export const StyledPolicyInputs = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px;
    gap: 32px;
`;

export const StyledAddPolicySubmitButtonContainer = styled.div`
    border-top: 2px solid ${theme.colors.planeGreenLight};;
    padding: 24px 40px;
`;

export const StyledAddPolicyButton = styled.div`
  width: fit-content;
`;

export const StyledRadioInputGroup = styled.div`
    display: flex;
    gap: 12px;
`;

export const StyledRadioButtonContainer = styled.div`
    display: flex;
    > div {
        margin: 0;
        > div {
            margin: 0;
            > div {
                margin-left: 0;
            }
            > div:last-child {
                margin-right: 21px;
            }
        }
    }
`;

export const StyledHorizontalDivider = styled.div`
    height: 1px;
    min-width: 100%;
    background-color: ${theme.colors.defaultBorder};
`;

export const StyledUploadPillWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const StyledUploadPillHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const StyledUploadPillContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const StyledUploadPillHelper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledTemplateContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-radius: 12px;
    background-color: ${theme.colors.surface};
    gap: 12px;
`;

export const StyledCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  padding: 16px;
  background-color: ${(p) => p.theme.colors.surface};
`;

export const StyledCheckboxInput = styled.input`
    height: 16px;
    width: 16px;
    cursor: pointer;
    &:checked {
        accent-color: ${(p) => p.theme.colors.primary};
    }
`;


export const StyledTemplateInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const StyledTemplateButton = styled.div`
    width: 90px;
`;

export const StyledTemplateInfoTitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const StyledUploadPill = styled.div`
    height: 150px;
`;

export const StyledSelectedFile = styled.div`
    > div {
        padding: 16px 20px;
    }
`;

export const StyledAdditionalBenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledAdditionalBenefitsText = styled.div`
  display: flex;
  text-align: center;
`;

export const StyledInfoIcon = styled.img`
  width: 20px;
  height: 20px;
  aspect-ratio: 1;
`;

export const StyledPlansNumberHelperText = styled.div`
  margin-top: 5px;
`;
